import React from "react";
import supports from "../assets/support.jpg"
import {BsFillTelephoneFill } from "react-icons/bs";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";
import {BiSupport} from "react-icons/bi";
import { Link } from "react-scroll";

const Support = () => {
  return (
    <div name='support' className='w-full mt-24'> 
    <div className='w-full h-[700px] bg-gray-900/90 absolute '>
      <img className='w-full h-full object-cover mix-blend-overlay' src={supports} alt="/" />
    </div>
    
    <div className='max-w-[1240px] mx-auto text-white relative'>
        <div className='px-4 py-12'>
            <h2 className='text-3xl pt-8 text-slate-300 uppercase text-center'>Support</h2>
            <h3 className='text-5xl font-bold py-6 text-center'>Finding the right team</h3>
            <p className='py-4 text-3xl text-slate-300'> Systazone is not an entity, it’s a family that represents togetherness for over two decades of a successful journey. For Systazone, the definition of success is to transcend innovative ideas of people to reality with the help of our tech expertise, this is what we, as a Team, want to be remembered for!</p>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
            <div className='bg-white rounded-xl shadow-2xl'>
                <div className='p-8'>
                    <BsFillTelephoneFill className='w-20 h-20 bg-indigo-600 text-white rounded-lg mt-[-4rem]' />
                    <h3 className='font-bold text-2xl my-6'>Our Work</h3>
                    <p className='text-gray-600 text-xl'>Transforming Your Project into Revenue Generating Business Around the Globe.Make Systazone part of your team and let us take your business to the next level.</p>
                </div>
                <div className='bg-slate-100 pl-8 py-4'>
                   <Link 
                   to="contact" 
                   smooth
                   duration={500}>
                  <p className='flex items-center text-indigo-600 cursor-pointer'>Contact Us <MdOutlineKeyboardArrowRight className='w-5 ml-2' /></p>
                   </Link> 
                </div>
            </div>
            <div className='bg-white rounded-xl shadow-2xl'>
                <div className='p-8'>
                    <BiSupport className='w-20 h-20 bg-indigo-600 text-white rounded-lg mt-[-4rem]' />
                    <h3 className='font-bold text-2xl my-6'>Technical Support</h3>
                    <p className='text-gray-600 text-xl'> Our unrivaled knowledge of the latest technologies and our commitment to customer service ensures your business gets the attention it deserves. We’ve worked with some big names in recent years..</p>
                </div>
                <div className='bg-slate-100 pl-8 py-4'>
                <Link 
                   to="contact" 
                   smooth
                   duration={500}>
                  <p className='flex items-center text-indigo-600 cursor-pointer'>Contact Us <MdOutlineKeyboardArrowRight className='w-5 ml-2' /></p>
                   </Link> 
                </div>
            </div>
            <div className='bg-white rounded-xl shadow-2xl'>
                <div className='p-8'>
                    <FaHandshake className='w-20 h-20 bg-indigo-600 text-white rounded-lg mt-[-4rem]' />
                    <h3 className='font-bold text-2xl my-6'>Join us</h3>
                    <p className='text-gray-600 text-xl'> One who has agreed to become the member of the company by entering his name and email into the 'Contact us'..</p>
                </div>
                <div className='bg-slate-100 pl-8 py-4'>
                <Link 
                   to="contact" 
                   smooth
                   duration={500}>
                  <p className='flex items-center text-indigo-600 cursor-pointer'>Contact Us <MdOutlineKeyboardArrowRight className='w-5 ml-2' /></p>
                   </Link> 
                </div>
            </div>
        </div>
    </div>



    
</div>
  )}
export default Support;