import React from "react";
import {motion} from 'framer-motion'
import HeroImage from "../assets/ta.png";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Counter from "./Counter";
import Webimg from "../assets/web.png"
import Apimg from "../assets/png.png"
import Gameimg from "../assets/game.png"
import Proimg from "../assets/product.png"
import Quaimg from "../assets/quality.png" 
import Machineimg from "../assets/mac.png"

import { Link } from "react-router-dom";


const Home = () => {
  return (
   <div name="/" className="">
     <div
    
    
    className="h-screen w-full bg-gradient-to-b from-white via-gray-200 to-gray-100 mt-24"
  >
    <motion.div
    initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
      <div className="flex flex-col justify-center h-full">
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
          Hello we are Ready 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        We, at Systazone solution, perfectly combine talent, innovation, and expertise to develop the top-notch websites and E-commerce sites meeting all client requirements with maximum customer satisfaction.We strive for the greatness and beyond in the era of Web Development.
        </p>

        <div>
          <Link
            to="all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>

      <div>
        <img
          src={HeroImage}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>
    </motion.div>
 
  </div>

  <div
    
    
    className="h-screen w-full bg-gradient-to-b from-gray-100 via-gray-200 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
   whileInView={{opacity:1}}
   transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div>
        <img
          src={Apimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Mobile App Development 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        Developing mobile apps over the years and collaborating on several different projects that fulfills your needs, we’ve gained profound expertise in this domain. We help you build mobile app development that brings in flawless customer retention and enterprise at a valuable amount. Our work of successfully launched apps are notable in the projects delivered to thousands of our clients globally. We also augment your current team for a finite period.
        </p>

        <div>
          <Link
            to="all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>

    
    </motion.div>
 
  </div>
 
 
  <div
    
    
    className="h-screen w-full bg-gradient-to-b from-white via-gray-200 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Web Development 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        People nowadays are mobile first. So when you plan to create a website, we definitely build it mobile friendly with a robust mobile backend along with fulfilling your web development requirements. Our various clients hire us for our capable command over web programming languages like PHP, .NET, Java and also build CMS, E-Commerce and Business Websites. Our work is not about completing only a project, for us, it’s more than that.
        </p>

        <div>
          <Link
            to="all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>
      <div>
        <img
          src={Webimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>


  <div
    
    
    className="h-screen w-full bg-gradient-to-b from-white via-gray-200 to-gray-100"
  >
    <motion.div
   
   initial={{opacity:0}}
   whileInView={{opacity:1}}
   transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div>
        <img
          src={Gameimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Game Development 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        The more entertaining we want the game to be, the more complex its coding can be. But don’t worry! Our game developers are proficient enough to deliver you with a game that is not only fun to the users, but also gives an amazing gaming experience. We take care of all the factors it takes to make a game creative, smooth and attractive enough for you to get a big fat number of downloads. We are experts in Native Game Development and 2D Game Development.
        </p>

        <div>
          <Link
            to="all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>

    
    </motion.div>
 
  </div>


  <div
    
    
    className="h-screen w-full bg-gradient-to-b from-gray-100 via-gray-200 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Product Design 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        We give a neat, decent and attractive look to your apps, websites and customized software. An attractive and soothing design to an interesting and organized interface is like adding a cherry on the cake. Irrespective of the size of your business, your website and its look and feel represents your company’s work. While you do business, we take care of everything, from color, font size to font style to take your products onto the next level.Product design and development is the process to achieve business goals to help brands make consistently successful products.
        </p>

        <div>
          <Link
            to="all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>
      <div>
        <img
          src={Proimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>

  <div
    
    
    className="h-screen w-full bg-gradient-to-b from-white via-gray-200 to-gray-100"
  >
    <motion.div
   
   initial={{opacity:0}}
   whileInView={{opacity:1}}
   transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div>
        <img
          src={Quaimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Quality Assurance 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        We detect and resolve the bugs beforehand at every phase of developing the software. So that, your users never get a word to complain about the errors they face but only about the seamless and user-friendly features that they enjoy while using the software. Our software tester experts track down all the bugs found and make sure to rectify them before the final launch of the product to you. Our quality analysts are hands-on experts in all the testing areas.
        </p>

        <div>
          <Link
            to="all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>

    
    </motion.div>
 
  </div>

  <div
    
    
    className="h-screen w-full bg-gradient-to-b from-gray-100 via-gray-200 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Machine learning 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        The three machine learning types are supervised, unsupervised, and reinforcement learning and we are doing all of them . Machine learning is a subfield of artificial intelligence, which is broadly defined as the capability of a machine to imitate intelligent human behavior. Artificial intelligence systems are used to perform complex tasks in a way that is similar to how humans solve problems.
        </p>

        <div>
          <Link
            to="all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>
      <div>
        <img
          src={Machineimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>

  
  <div
    
    
    className="h-screen w-full bg-gradient-to-b from-white via-gray-200 to-gray-100"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        We’ve got a Rich Experience of Multiple Industries WorldWide
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-xl">
        We have a team of 80+ professionals who can code your ideas into excellent software
        </p>
       <Counter />
      
       
      </div>
      
     
    
    </motion.div>
 
  </div>


   </div>

    
     
    
  );
};

export default Home;