import React, { Fragment,useState } from 'react'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'
import "./Counter.css"

const Counter = () => {
    const [counterOn , setCounterOn ] = useState(false)
  return (
    <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
   <Fragment>
    <section className='counter'>
   
     <div className="counter-row">

    <div className="counter-column">
        <strong data-number='100'>
        {counterOn && <CountUp className='number' start = {0}  end = {18} duration={2} />}
        
        
        </strong>
        <span>On Going <br />Projects</span>
    </div>

    <div className="counter-column">
        <strong data-number='1050'>
        {counterOn && <CountUp className='number' start = {0}  end = {500} duration={5} />}
        
        </strong>
        <span>Total <br />Projects</span>
    </div>

    <div className="counter-column">
        <strong data-number='200'>
        {counterOn && <CountUp className='number' start = {0}  end = {350} duration={5} />}
        
        </strong>
        <span>Websites  <br />Developed</span>
    </div>

    <div className="counter-column">
        <strong data-number='900'>
        {counterOn && <CountUp className='number' start = {0}  end = {600} duration={5} />}
       
        </strong>
        <span>Clients  <br /> Served </span>
    </div>


     </div>
     

    </section>
    
    </Fragment>
    </ScrollTrigger>
  )
}

export default Counter