// import React, {useState} from 'react';
// import { Link } from 'react-router-dom'



// import { MenuIcon, XIcon } from '@heroicons/react/outline';

// const Navbar = () => {
//     const [nav, setNav] = useState(false)
//     const handleClick = () => setNav(!nav)

//     const handleClose =()=> setNav(!nav)

//   return (
//     <div className=''>
//       <div className='px-2 flex justify-between items-center w-full h-full'>
//         <div className='flex items-center'>
//           <h1 className='text-3xl font-bold mr-4 sm:text-4xl'>BRAND.</h1>
//           <ul className='hidden md:flex'>
//           <li><Link to="home" smooth={true} duration={500}>Home</Link></li>
//           <li><Link to="about" smooth={true} offset={-200} duration={500}>About</Link></li>
//           <li><Link to="support" smooth={true} offset={-50} duration={500}>Support</Link></li>
//           <li><Link to="platforms" smooth={true} offset={-100} duration={500}>Platforms</Link></li>
//           <li><Link to="pricing" smooth={true} offset={-50} duration={500}>Pricing</Link></li>
//           </ul>
//         </div>
//         <div className='hidden md:flex pr-4'>
//           <button className='border-none bg-transparent text-black mr-4'>
//             Sign In
//           </button>
//           <button className='px-8 py-3'>Sign Up</button>
//         </div>
//         <div className='md:hidden mr-4' onClick={handleClick}>
//             {!nav ? <MenuIcon className='w-5' /> : <XIcon className='w-5' />}
          
//         </div>
//       </div>

//       <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8'}>
//           <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="home" smooth={true} duration={500}>Home</Link></li>
//           <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="about" smooth={true} offset={-200} duration={500}>About</Link></li>
//           <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="support" smooth={true} offset={-50} duration={500}>Support</Link></li>
//           <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="contact" smooth={true} offset={-100} duration={500}>Platforms</Link></li>
//           <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="pricing" smooth={true} offset={-50} duration={500}>Pricing</Link></li>

//         <div className='flex flex-col my-4'>
//             <button className='bg-transparent text-indigo-600 px-8 py-3 mb-4'>Sign In</button>
//             <button className='px-8 py-3'>Sign Up</button>
//         </div>
//       </ul>
//     </div>
//   );
// };

// export default Navbar;

import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";



const NavBar = () => {
  const [nav, setNav] = useState(false);

  const links = [
  
    {
      id: 2,
      link: "About",
      
    },
    {
      id: 3,
      link: "support",
    },
    {
      id: 4,
      link: "skills",
    },
    {
      id: 5,
      link: "all-in-one",
    },
    {
      id: 6,
      link: "contact",
    },
    {
      id: 7,
      link: "carrier",
    },
  ];

  return (
    <div className="flex justify-between items-center z-50 px-4 top-0 py-2 w-full h-20  text-black bg-white fixed drop-shadow-lg ">
      <div>
        <Link className="hover:text-green-500" to={"/"}>
        <h1 className="text-5xl font-signature ml-2">Systazone Solution</h1>
        </Link>
      </div>

      <ul className="hidden md:flex">
        {links.map(({ id, link }) => (
          <li
            key={id}
            className="px-4 cursor-pointer capitalize font-medium text-gray-500 hover:scale-105 duration-200"
          >
            <Link to={link} smooth duration={500}>
              {link}
            </Link>
          </li>
        ))}
      </ul>

      <div
        onClick={() => setNav(!nav)}
        className="cursor-pointer pr-4 z-10 text-gray-500 md:hidden"
      >
        {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>

      {nav && (
        <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-gray-500">
          {links.map(({ id, link }) => (
            <li
              key={id}
              className="px-4 cursor-pointer capitalize py-6 text-4xl"
            >
              <Link
                onClick={() => setNav(!nav)}
                to={link}
                smooth
                duration={500}
              >
                {link}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavBar;
