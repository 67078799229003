// import React from 'react';



// const Footer = () => {
//   return (
//     <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-black'>
//       <div>
//         <h1 className='w-full text-5xl ml-2 font-bold font-signature text-[#00df9a]'>Systazone</h1>
//         <p className='py-4'>" Work for IT development "</p>
//         <p className='py-4'>Copyright © 2023 by Systazone solution Pvt Ltd</p>
        
        
//       </div>
//       <div className='lg:col-span-2 flex justify-between mt-6'>
//     <div>
//         <h6 className='font-medium text-black-400'>SERVICES</h6>
//         <ul>
//             <li className='py-2 text-sm'>• React Web Development</li>
//             <li className='py-2 text-sm'>• Node Development</li>
//             <li className='py-2 text-sm'>• python Development</li>
//             <li className='py-2 text-sm'>• Laravel Development</li>
//             <li className='py-2 text-sm'>• PHP Development</li>
//             <li className='py-2 text-sm'>• Machine Learning</li>
//         </ul>
//     </div>
//     <div>
//         <h6 className='font-medium text-black-400'>Other services</h6>
//         <ul>
//             <li className='py-2 text-sm'>• Web/Graphics Design</li>
//             <li className='py-2 text-sm'>• Android & IOS App</li>
//             <li className='py-2 text-sm'>• Ui & Ux</li>
//             <li className='py-2 text-sm'>• Digital Marketing</li>
//             <li className='py-2 text-sm'>• Information & System Architecture</li>
//         </ul>
//     </div>
//     <div>
//         <h6 className='font-medium text-black-400'>HIRE DEVELOPER</h6>
//         <ul>
//             <li className='py-2 text-sm'>• Hire ASP.net,MVC Developer</li>
//             <li className='py-2 text-sm'>• Hire python Developer</li>
//             <li className='py-2 text-sm'>• Hire react & node Developer</li>
//             <li className='py-2 text-sm'>• Hire Php Developer</li>
//             <li className='py-2 text-sm'>• Hire Laravel Developer</li>
//             <li className='py-2 text-sm'>• Hire Shopware Developer</li>
//         </ul>
//     </div>
//     <div>
//         <h6 className='text-4xl font-signature text-black-400'>Location</h6>
//         <ul>
            
//             <li className='py-2 text-sm'>Rashi Circle</li>
//             <li className='py-2 text-sm'>Katargam,Surat</li>
//             <li className='py-2 text-sm'>Gujarat 395004</li>
//             <li className='py-2 text-sm'>Call : 9727772757</li>
//         </ul>
//     </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;
import React from 'react'
import { Link } from "react-router-dom";


import {
FaFacebook,
FaInstagram,
FaLinkedin
} from 'react-icons/fa'
import { HiOutlineMail } from "react-icons/hi";

const Footer = () => {
  return (
    <div className='w-full mt-24 bg-slate-900 text-gray-300 py-y px-2'>
        <div className='max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8'>
            <div>
                <h6 className='font-bold uppercase pt-2'>HIRE DEVELOPER</h6>
                <ul>
                    <li className='py-1'>• Hire ASP.Net</li>
                    <li className='py-1'>• Hire Python Developer</li>
                    <li className='py-1'>• Hire React & Node Developer</li>
                    <li className='py-1'>• Hire Php Developer</li>
                    <li className='py-1'>• Hire Laravel Developer</li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'>SERVICES</h6>
                <ul>
                    <li className='py-1'>React Web Development</li>
                    <li className='py-1'>python Development</li>
                    <li className='py-1'>Laravel Development</li>
                    <li className='py-1'>PHP Development</li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'>Company</h6>
                <ul>
                   <Link className='hover:text-green-500'
                   to="/About" 
                   smooth
                   duration={500}
                   ><li className='py-1 cursor-pointer'>About</li></Link> 
                  <Link
                  className='hover:text-green-500'
                  to="/contact" 
                  smooth
                  duration={500}>
                   <li className='py-1 cursor-pointer'>Jobs</li>
                   </Link> 
                   <Link
                   className='hover:text-green-500'
                  to="/support" 
                  smooth
                  duration={500}><li className='py-1 cursor-pointer'>support</li></Link> 
                    
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'>Legal</h6>
                <ul>
                    
                <Link className='hover:text-green-500' to="Term">  <li className='py-1'>Privacy Policies</li></Link>
                   <Link className='hover:text-green-500'  to="Term"> <li className='py-1'>Terms Conditions</li></Link>
                    
            
                </ul>
            </div>
            <div className='col-span-2 pt-8 md:pt-2'>
                <p className='font-bold uppercase'>Subscribe to our newsletter</p>
                <p className='py-4'>The latest news, articles, and resources, sent to your inbox weekly.</p>
                <form className='flex flex-col sm:flex-row'>
                    <input className='w-full p-2 mr-4 rounded-md mb-4' type="email" placeholder='Enter email..'/>
                    <button className='p-2 mb-4 hover:text-green-500'>Subscribe</button>
                </form>
            </div>
        </div>

        <div className='flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500'>
        <p className='py-4'> <span className='text-xl'>©</span> 2023 Systazone Solution, LLC. All rights reserved</p>
        <div className='flex justify-between sm:w-[300px] pt-4 text-2xl'>
           <a className='hover:text-green-500' href='https://www.facebook.com/people/Systazone-Solution/100088946541858/' ><FaFacebook /></a>
           <a className='hover:text-green-500' href='https://www.instagram.com/systazonesolution/' > <FaInstagram /></a>
           <a className='hover:text-green-500' href='https://linkedin.com/company/systazonesolution' ><FaLinkedin /></a>
           <a className='hover:text-green-500' href='mailto:systazone@gmail.com' ><HiOutlineMail /></a>
           
                  </div>
        </div>
    </div>
  )
}

export default Footer