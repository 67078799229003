import React from 'react';
import { FcCheckmark } from "react-icons/fc"
import { Link } from 'react-scroll';
import All from "../assets/all.gif"
import Ecoimg from "../assets/eco.png"
import {motion} from 'framer-motion'
import Macimg from "../assets/mach.png"
import Desimg from "../assets/design.png"
import Cloudimg from "../assets/cloud.png"
import Dataimg from "../assets/big.png"
import Digimg from "../assets/digi.png"

const AllInOne = () => {
  return (
    <div name='all-in-one' className='w-full my-32'>
      <div className='max-w-[1240px] mx-auto px-2'>
        <h2 className='text-5xl font-bold text-center'>All-In-One</h2>
        <p className='text-2xl py-8 text-gray-500 text-center'>
        In the era of digital economy, we provide excellent web development services with first class support and maintenance services to make our valued clients totally worry free.
        Systazone is web development company based in India with great passion and professionalism
        </p>

        <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-4 pt-4'>

          <div className='flex'>
            <div>
              <FcCheckmark className='w-7 mr-4 text-green-600' />
            </div>
            <div>
            <Link className="hover:cursor-pointer font-bold hover:text-green-600" to="web" smooth duration={500}> <h3 className='font-bold text-lg'>Web development</h3> </Link>
              {/* <p className='text-lg pt-2 pb-4'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Asperiores maxime deserunt voluptatibus consequatur similique
                voluptates!
              </p> */}
             <ul>
             <li className='py-2 text-lg pt-2 pb-4'>• React Web Development</li> 
             <li className='py-2 text-lg pt-2 pb-4'>• Node Development</li>
             <li className='py-2 text-lg pt-2 pb-4'>• python Development</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Laravel Development</li>
             <li className='py-2 text-lg pt-2 pb-4'>• PHP Development</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Machine Learning</li>
        </ul>
            </div>
          </div>
          <div className='flex'>
            <div>
              <FcCheckmark className='w-7 mr-4 text-green-600' />
            </div>
            <div>
            <Link className="hover:cursor-pointer font-bold hover:text-green-600" to="Mac" smooth duration={1000}> <h3 className='font-bold text-lg'>Machine Learning</h3></Link>
              <ul>
             <li className='py-2 text-lg pt-2 pb-4'>• Artificial intelligence </li>
             <li className='py-2 text-lg pt-2 pb-4'>• Data mining</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Speech recognition</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Medical diagnosis</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Statistical arbitrage</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Image recognition</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Predictive analytics</li>
        </ul>
            </div>
          </div>
          <div className='flex'>
            <div>
              <FcCheckmark className='w-7 mr-4 text-green-600' />
            </div>
            <div>
            <Link className="hover:cursor-pointer font-bold hover:text-green-600" to="Eco" smooth duration={1000}><h3 className='font-bold text-lg'>E- Commerce Development Services</h3></Link>
              <ul>
             <li className='py-2 text-lg pt-2 pb-4'>• MagentoMagento </li>
             <li className='py-2 text-lg pt-2 pb-4'>• Shopware DevelopmentShopware</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Shopify DevelopmentShopify</li>
             <li className='py-2 text-lg pt-2 pb-4'>• prestashop DevelopmentPrestashop</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Magento2 Migration</li>
        </ul>
            </div>
          </div>
          <div className='flex'>
            <div>
              <FcCheckmark className='w-7 mr-4 text-green-600' />
            </div>
            <div>
            <Link className="hover:cursor-pointer font-bold hover:text-green-600" to="Graphic" smooth duration={1000}><h3 className='font-bold text-lg'>Web Graphic Design Services</h3></Link>
              <ul>
             <li className='py-2 text-lg pt-2 pb-4'>• UI/UX Design </li>
             <li className='py-2 text-lg pt-2 pb-4'>• Website Designing</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Logo And Brochure Design</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Responsive Web Designing</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Graphic Design</li>
        </ul>
            </div>
          </div>
          <div className='flex'>
            <div>
              <FcCheckmark className='w-7 mr-4 text-green-600' />
            </div>
            <div>
            <Link className="hover:cursor-pointer font-bold hover:text-green-600" to="Cloud" smooth duration={1000}> <h3 className='font-bold text-lg'>Cloud server setup Aws Service Management</h3></Link>
              <ul>
             <li className='py-2 text-lg pt-2 pb-4'>• Managed Services </li>
             <li className='py-2 text-lg pt-2 pb-4'>• Operations and Reports</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Recommendations</li>
 
        </ul>
            </div>
          </div>
          <div className='flex'>
            <div>
              <FcCheckmark className='w-7 mr-4 text-green-600' />
            </div>
            <div>
            <Link className="hover:cursor-pointer font-bold hover:text-green-600" to="Data" smooth duration={1000}> <h3 className='font-bold text-lg'>Data management</h3></Link>
              <ul>
             <li className='py-2 text-lg pt-2 pb-4'>• Customer Relationship Management System </li>
             <li className='py-2 text-lg pt-2 pb-4'>• Data consolidation</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Data governance</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Data Warehouse systems</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Analytics tools</li>
        </ul>
            </div>
          </div>
          <div className='flex'>
            <div>
              <FcCheckmark className='w-7 mr-4 text-green-600' />
            </div>
            <div>
            <Link className="hover:cursor-pointer font-bold hover:text-green-600" to="Digi" smooth duration={1000}> <h3 className='font-bold text-lg'>Digital Marketing</h3></Link>
              <ul>
             <li className='py-2 text-lg pt-2 pb-4'>• SEO Services </li>
             <li className='py-2 text-lg pt-2 pb-4'>• Social Media Marketing</li>
             <li className='py-2 text-lg pt-2 pb-4'>• PPC Services</li>
             <li className='py-2 text-lg pt-2 pb-4'>• Facebook-ADS Services</li>
 
        </ul>
            </div>
          </div>
         
          

        </div>
      </div>

      <div>
      <div className='px-4 py-12'>
            
            <h3 className='text-5xl font-bold py-6 text-center' name="web">Web development</h3>
            <p className='py-4 text-3xl text-gray-500 justify-center text-center'> We are developing a website for any business. The Website will be attractive and user friendly. The web development is a best part for your business building. That understanding is our basic methodology for promoting your business. </p>
        </div>
        <div className='w-full h-[700px]'>
        <img className='w-full h-full object-cover mix-blend-overlay' src={All} alt="/" />
        </div>
      </div>

      <div
    
    name="Mac"
    className="h-screen w-full bg-gradient-to-b from-white via-gray-100 to-gray-200 mt-24" 
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
       <div>
        <img
          src={Macimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">

        
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Machine Learning 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        The three machine learning types are supervised, unsupervised, and reinforcement learning and we are doing all of them . Machine learning is a subfield of artificial intelligence, which is broadly defined as the capability of a machine to imitate intelligent human behavior. Artificial intelligence systems are used to perform complex tasks in a way that is similar to how humans solve problems.
        <li>Artificial intelligence</li>
        <li>Data mining</li>
        <li>Speech recognition</li>
        <li>Statistical arbitrage</li>
        <li>Image recognition</li>
        </p>
        
       
      </div>
     

    
    </motion.div>
 
  </div>

  <div
    
    name="Eco"
    className="h-screen w-full bg-gradient-to-b from-gray-200 via-gray-100 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        E-Commerece Develoment
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-md">
        Trying to grow your online business? Then our E-Commerce services are exactly what you need. Smartphones are in every pocket now & customers are doing local research on Internet before they go out & buy. E-commerce website development is investment & our e-commerce team is experienced in delivering e-commerce website which can bring the returns on investment in a quick period of time
        </p>
        <p className='text-gray-500 py-4 max-w-md text-md'>
        Our team’s wealth of experience in a wide variety of E-Commerce platforms – particularly WordPress – enable us to provide a diverse range of solutions and technical requirements. 
        </p>

      
      </div>
      <div>
        <img
          src={Ecoimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>

  <div
    
    name="Graphic"
    className="h-screen w-full bg-gradient-to-b from-white via-gray-100 to-gray-200" 
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
       <div>
        <img
          src={Desimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">

        
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Web Graphic Design 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        The three machine learning types are supervised, unsupervised, and reinforcement learning and we are doing all of them . Machine learning is a subfield of artificial intelligence, which is broadly defined as the capability of a machine to imitate intelligent human behavior. Artificial intelligence systems are used to perform complex tasks in a way that is similar to how humans solve problems.
        <li>Artificial intelligence</li>
        <li>Data mining</li>
        <li>Speech recognition</li>
        <li>Statistical arbitrage</li>
        <li>Image recognition</li>
        </p>
        
       
      </div>
     

    
    </motion.div>
 
  </div>

  <div
    
    name="Cloud"
    className="h-screen w-full bg-gradient-to-b from-gray-200 via-gray-100 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-6xl font-bold text-black">
        Cloud Management
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-md">
        Trying to grow your online business? Then our E-Commerce services are exactly what you need. Smartphones are in every pocket now & customers are doing local research on Internet before they go out & buy. E-commerce website development is investment & our e-commerce team is experienced in delivering e-commerce website which can bring the returns on investment in a quick period of time
        </p>
        <p className='text-gray-500 py-4 max-w-md text-md'>
        Our team’s wealth of experience in a wide variety of E-Commerce platforms – particularly WordPress – enable us to provide a diverse range of solutions and technical requirements. 
        </p>

      
      </div>
      <div>
        <img
          src={Cloudimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>

  <div
    
    name="Data"
    className="h-screen w-full bg-gradient-to-b from-white via-gray-100 to-gray-200" 
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
       <div>
        <img
          src={Dataimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">

        
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Data management 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        The three machine learning types are supervised, unsupervised, and reinforcement learning and we are doing all of them . Machine learning is a subfield of artificial intelligence, which is broadly defined as the capability of a machine to imitate intelligent human behavior. Artificial intelligence systems are used to perform complex tasks in a way that is similar to how humans solve problems.
        <li>Artificial intelligence</li>
        <li>Data mining</li>
        <li>Speech recognition</li>
        <li>Statistical arbitrage</li>
        <li>Image recognition</li>
        </p>
        
       
      </div>
     

    
    </motion.div>
 
  </div>

  <div
    
    name="Digi"
    className="h-screen w-full bg-gradient-to-b from-gray-200 via-gray-100 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-6xl font-bold text-black">
        Digital Marketing
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-md">
        Trying to grow your online business? Then our E-Commerce services are exactly what you need. Smartphones are in every pocket now & customers are doing local research on Internet before they go out & buy. E-commerce website development is investment & our e-commerce team is experienced in delivering e-commerce website which can bring the returns on investment in a quick period of time
        </p>
        <p className='text-gray-500 py-4 max-w-md text-md'>
        Our team’s wealth of experience in a wide variety of E-Commerce platforms – particularly WordPress – enable us to provide a diverse range of solutions and technical requirements. 
        </p>

      
      </div>
      <div>
        <img
          src={Digimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>
    </div>
  );
};

export default AllInOne;