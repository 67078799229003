// import React from "react";

// const About = () => {
//   return (
//     <div
//       name="about"
//       className="w-full h-screen bg-gradient-to-b from-gray-100 via-gray-200 to-white"
//     >
//       <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
//         <div className="pb-8">
//           <p className="text-4xl font-bold inline border-b-4 border-gray-500">
//             About
//           </p>
//         </div>

//         <p className="text-xl mt-20">
//         Systazone is not an entity, it’s a family that represents togetherness for over two decades of a successful journey. For Systazone, the definition of success is to transcend innovative ideas of people to reality with the help of our tech expertise, this is what we, as a Team, want to be remembered for!
//         <br />
//         We provide an open & entrepreneurial environment to our employees, to support that we offer a dynamic, fast-paced & flexible environment to our employees.
//         </p>

//         <br />

//         <p className="text-xl">
//         We nurture the relationship with our employees by celebrating their achievements, sharing their happiness and problems.
//         <br />
//        You will get an opportunity to work in an open environment where you have freedom to create, freedom to articulate your ideas and implement them. You will surely experience the blend of passion & processes which brings out the best.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default About;
import React from 'react'
import Team  from "../assets/team.png"
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Webimg from "../assets/web.png"
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'
import Teimg from "../assets/te.png" 
import Mimg from "../assets/gro.png"
import Visionimg from "../assets/vision.png";
import Worldimg from "../assets/worl.jpg";

const About = () => {
  return (
   
    <div name='About'  className='w-full  my-32'>
        
       
    <div className='w-full h-[700px] bg-gray-900/100 absolute '>
      <img className='w-full h-full object-cover mix-blend-overlay' src={Team} alt="/" />
    </div>
    
    <div className='max-w-[1240px] mx-auto text-white relative'>
        <div className='px-4 py-12'>
            <h2 className='text-3xl pt-8 text-slate-300 uppercase text-center'>About Us</h2>
            <h3 className='text-5xl font-bold py-6 text-center'>All You Need to Know About Us
We Converted Our Ideas into Reality with Powerful Technologies</h3>
            <p className='py-4 text-3xl text-slate-300'> Systazone is not an entity, it’s a family that represents togetherness for over two decades of a successful journey. For Systazone, the definition of success is to transcend innovative ideas of people to reality with the help of our tech expertise, this is what we, as a Team, want to be remembered for!</p>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
          
          
            
        </div>
    </div>





<div className='h-screen w-full mt-52'>
<div className='w-full h-[700px]  absolute'>
            <div className='text-center'>
                <h2 className='text-5xl font-bold'>Trusted by all across the world</h2>
                <p className='text-3xl py-6 text-gray-500'>We provide an open & entrepreneurial environment to our employees, to support that we offer a dynamic, fast-paced & flexible environment to our employees</p>
            </div>

            <div className='grid md:grid-cols-3 gap-1 px-2 text-center'>
                <div className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-6xl  font-bold text-indigo-600'>100%</p>
                    <p className='text-gray-400 mt-2'>Completion</p>
                </div>
                <div  className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-6xl font-bold text-indigo-600'>24/7</p>
                    <p className='text-gray-400 mt-2'>Delivery</p>
                </div>
                <div className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-6xl font-bold text-indigo-600'>100K</p>
                    <p className='text-gray-400 mt-2'>Transactions</p>
                </div>
            </div>
            
            <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
         Who we are 
        </h2> 
        <p className='text-xl text-gray-500'>Delivering The Best Software Solutions With Trusted Quality Product Worldwide.</p>
      </div>
      </motion.div>


        </div>
</div>
<div
    
    
    className="h-screen w-full bg-gradient-to-b from-white via-gray-200 to-white mt-24"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        How We Started 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-sm">
        Systazone Solution Pvt. Ltd. is one of the most trusted Software development companies of India today. With a futuristic vision to grow in the tech industry, this company passionately in 2018 with a small team of employees providing web designing as primary service. With their untiring energy and allegiance towards the work, in a couple of years, the company witnessed a roaring success and growth. We have now grown to a workforce of over 80+ action-driven team members and offering a wide range of services such as Mobile Application Development (Android & iOS), Website Development (Custom, ECommerce, etc.), Custom Software Development, Desktop Application Development, CMS Development, Game Development, ERP Solutions, FinTech Solutions, Blockchain Solutions and almost everything under the hood of computing and communication technology.
        </p>

        <div>
          <Link
            to="/all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>
      <div>
        <img
          src={Webimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>

  <div
    
    
    className="h-screen w-full bg-gradient-to-b from-white via-gray-100 to-gray-200"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
       <div>
        <img
          src={Teimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">

        
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        How We Grew 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        Our company grew leaps and bounds with over 1250+ successful projects till date. For more than a decade, we have been delivering software development solutions and empowering businesses around the world to enrich human lives with tech utility by reaching tech business goals year over year.

        We take immense pleasure to say that 70% of our business comes from existing clients. Our clients choose us time and time again as they trust us to deliver impeccable solutions that meet their specific business requirements. Needless to say, transparency in our business deal has been our greatest strength.
        </p>

        <div>
          <Link
            to="/all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>
     

    
    </motion.div>
 
  </div>

  <div
    
    
    className="h-screen w-full bg-gradient-to-b from-gray-200 via-gray-100 to-white "
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Our Mission 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-sm">
         We at Systazone Solution Pvt. Ltd. are committed to providing global offshore services with the latest cutting edge technology by creating quality software products.We aim to build all our products with an amalgamation of our values along with an ensurity of customer requirements.
        </p>

        <div>
          <Link
            to="/all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>
      <div>
        <img
          src={Mimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>

  <div
    
    
    className="h-screen w-full bg-gradient-to-b from-white via-gray-100 to-gray-200"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
       <div>
        <img
          src={Visionimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">

        
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Our Vision
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        Our vision is to be a leading ‘e-friendly’ IT firm which gives a nurturing corporate ethos for their employees and be a trusted choice of e-digital services for every client over and over again.Also, we figure that as SMEs and MMEs in every industry must be digitized, they must also balance environmental friendliness to preserve mother earth. Moreover, we yearn and try to create a competent and content corporate environment where everyone can be their own boss and work out of one’s one accord.
        </p>

        <div>
          <Link
            to="/all-in-one" 
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
          >
            We are doing
            <span className="group-hover:rotate-90 duration-300">
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
          </Link>
        </div>
      </div>
     

    
    </motion.div>
 
  </div>
   
   <div className='bg-gradient-to-b from-gray-200 via-gray-100 to-white'>
   <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        We Serve To 10+ Countries worldwide 
        </h2> 
        <p className='text-xl'>We have successfully completed more than 800+ projects in Various Domains like Medical , Healthcare , Food , Travel , Networking and Blockchain Industries.We have 60% Recurring ratio in terms of Client Project Work.</p>
      </div>
      </motion.div>
   </div>
   
   <div>
        <img
          src={Worldimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>
  
       
    </div>
  )
  
}

export default About
// import "./about.css";
// // import Award from "../../img/award.png";

// const About = () => {
//   return (
//     <div className="about">
//       <div className="a-left">
//         <div className="a-card bg"></div>
//         <div className="a-card">
//           <img
//             src="https://images.pexels.com/photos/3585047/pexels-photo-3585047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
//             alt=""
//             className="a-img"
//           />
//         </div>
//       </div>
//       <div className="a-right">
//         <h1 className="a-title">About Me</h1>
//         <p className="a-sub">
//           It is a long established fact that a reader will be distracted by the
//           readable content.
//         </p>
//         <p className="a-desc">
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
//           minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//           aliquip ex ea commodo consequat duis aute irure dolor in reprehende.
//         </p>
//         <div className="a-award">
//           {/* <img src={Award} alt="" className="a-award-img" /> */}
//           <div className="a-award-texts">
//             <h4 className="a-award-title">International Design Awards 2021</h4>
//             <p className="a-award-desc">
//               Nemo enim ipsam voluptatem quia voluptas sit aspernatur autodit
//               and fugit.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default About;