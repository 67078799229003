import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const notify = () => toast("Form submited!");

const Contact = () => {
  const formRef = useRef();
 

  const handleSubmit = (e) => {
    e.preventDefault();
    notify();
    emailjs.sendForm('service_k451zmq', 'template_n61mhui', formRef.current, '2fmaxxSOhuD5K0PRg')
      .then((result) => {
          console.log(result.text);
          
          
      }, (error) => {
          console.log(error.text);
      });
  };
 

 
  return (
    <div
      name="contact"
      className="w-full h-screen bg-gradient-to-b ffrom-white via-gray-200 to-gray-100 mt-32"
    >
      <div className="flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Contact
          </p>
          <p className="py-6">Submit the form below to get in touch with us</p>
        </div>

        <div className=" flex justify-center items-center">
          <form
            ref={formRef} onSubmit={handleSubmit}
            className=" flex flex-col w-full md:w-1/2"
            
          >
            <input
              type="text"
              name="user_name"
              placeholder="Enter your name"
              className="p-2 bg-transparent border-2 rounded-md text-blue-500 focus:outline-none"
              required
            />
             <input
              type="text"
              name="user_subject"
              
              placeholder="Enter your subject"
              className="my-4 p-2 bg-transparent border-2 rounded-md text-blue-500 focus:outline-none"
              required
            />
            <input
              type="email"
              name="user_email"
              
              placeholder="Enter your email"
              className="my-4 p-2 bg-transparent border-2 rounded-md text-blue-500 focus:outline-none"
              required
            />
            <textarea
              name="message"
              placeholder="Enter your message"
              rows="10"

              className="p-2 bg-transparent border-2 rounded-md text-blue-500 focus:outline-none"
              required
            ></textarea>
             <button  className="text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300">
              Let's talk 
            </button>
           <ToastContainer/>
          
         
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
// import React from "react";
// import {  useState } from "react"

// const Contact = () =>{
//   const [formState, setFormState] = useState({});
  
 

//   const changeHandler = (event) =>{
//     setFormState({...formState,[event.target.name]:event.target.value });
//   }

//   const submitHandler = (event) =>{
    
//     const config = {
//       SecureToken : "cdfdde8b-7f68-4853-8cad-67fe226c455b",
//       To : 'systazone@gmail.com',
//       From : formState.email,
//       Subject : "This is the my contact form",
//       Body : `${formState.name} conected to you over email`
  
//     }
  
   
//     if (window.Email){
//       window.Email.send(config).then(()=> alert("email send successfully"));
//     }
//   }
//   return(
//     <div>
//       <form className="flex flex-col justify-center items-center mt-24" onSubmit={submitHandler}>
//         <input type="text" name="value" placeholder="name" value={formState.name || "hm"} onChange={changeHandler} className="border border-blue-900" />
//         <input type="email" name="email" value={formState.email || ""} placeholder="email" onChange={changeHandler} className="border border-blue-900" />
//      <input type="submit" value="send email" />  
//       </form>
     
//     </div>
//   )
// }

// export default Contact