import React from "react";
import {motion} from 'framer-motion'

import python from "../assets/python.png";
import Php from "../assets/php.png";
import javascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import nextjs from "../assets/nextjs.png";
import graphql from "../assets/graphql.png";
import laravel from "../assets/laravel.png";
import tailwind from "../assets/tailwind.png";
import asp from "../assets/asp.png";
import { Link } from "react-scroll";
import Pyimg from "../assets/py.png" 
import Phimg from "../assets/pp.png"
import JSimg from "../assets/js.png"
import Reactimg from "../assets/rea.png"
import Tailimg from "../assets/tail.png"
import Nextimg from "../assets/next.png"
import Graphimg from "../assets/graph.png"
 import Laraimg from "../assets/lara.png"
 import Aspimg from "../assets/as.png"


const Skills = () => {
  const techs = [
    {
      id: 1,
      src: python,
      title: "python",
      style: "shadow-yellow-500",
   
    },
    {
      id: 2,
      src: Php,
      title: "Php",
      style: "shadow-blue-500",
    },
    {
      id: 3,
      src: javascript,
      title: "JavaScript",
      style: "shadow-yellow-500",
    },
    {
      id: 4,
      src: reactImage,
      title: "React",
      style: "shadow-blue-600",
    },
    {
      id: 5,
      src: tailwind,
      title: "Tailwind",
      style: "shadow-sky-400",
    },
    {
      id: 6,
      src: nextjs,
      title: "NextJS",
      style: "shadow-white",
    },
    {
      id: 7,
      src: graphql,
      title: "GraphQL",
      style: "shadow-pink-400",
    },
    {
      id: 8,
      src: laravel,
      title: "Laravel",
      style: "shadow-red-400",
    },
    {
      id: 9,
      src: asp,
      title: "ASP",
      style: "shadow-blue-400",
    },
   
  ];

  return (

    


<div>
    <motion.div
    initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
      name="skills"
      className="bg-gradient-to-b from-gray-100 via-gray-200 to-white w-full h-screen mt-24"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-black">
        <div>
        <div className='text-center mt-24'>
                <h2 className='text-5xl font-bold'>Our Specialization</h2>
                
            </div>
          <p className="py-6 text-center font-bold text-gray-600">These are the technologies We've worked with</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
       
          {techs.map(({ id, src, title, style}) => (
             <Link className="hover:cursor-pointer font-bold" to={title} smooth duration={500}>
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
            >
              
              {title}
           
              <img src={src} alt="" className="w-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
            </Link>
          ))}
        
        </div>
      </div>
    </motion.div>

    <div
    
    name="python"
    className="h-screen w-full bg-gradient-to-b from-white via-gray-100 to-gray-200 mt-24" 
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
       <div>
        <img
          src={Pyimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">

        
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        python 
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        Python is one of the fastest developing programming languages in the world. Powerful, easy-to-use and Flexible language.
        Python is used to develop a wide range of projects especially those planned for text and pictures. It is an open source program that can be developed with simpler commands and minimal text in data mining as compared to any other programming languages.
        </p>

       
      </div>
     

    
    </motion.div>
 
  </div>

  <div
    
    name="Php"
    className="h-screen w-full bg-gradient-to-b from-gray-200 via-gray-100 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
       PHP
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-md">
        We’ve got a powerful team of PHP developers that can help you with an array of services. PHP is a stunning framework that can build great projects with the least number of line codes. Delivering result-oriented PHP websites that are strong, scalable and have a better web architecture for all business types.
        </p>

      
      </div>
      <div>
        <img
          src={Phimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>


  <div
    
    name="JavaScript"
    className="h-screen w-full bg-gradient-to-b from-white via-gray-100 to-gray-200" 
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
       <div>
        <img
          src={JSimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">

        
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        JavaScript
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        It is a single threaded, interpreted, high level programming language and is one of the three main things of the world wide web.
        Javascript was developed as a frontend web development language to bridge the gap between java and scripting languages, to increase web engagement by making it more interactive and engaging. Javascript is platform independent and is easily understood and interpreted by all the browsers. It has powerful Javascript frameworks which are also effortlessly debugged. Some of your requirements like adding animations on websites, continuously updating fresh content, constructing interactive forms that identify the mistakes done by the users when they are filling it, search boxes that are responsive to user queries in real time.
        </p>

       
      </div>
     

    
    </motion.div>
 
  </div>

  <div
    
    name="React"
    className="h-screen w-full bg-gradient-to-b from-gray-200 via-gray-100 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
       React
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-md">
        Main purpose of React Js is to be simple, scalable and quick. Also, it is used by many Fortune 500 companies.
        With every new day, there are some or the other new advancements happening in the world of technology. In that, front-end technology is also continuously evolving and React.JS is breaking records in the website development space. According to a survey by Stack Overflow, React Js Frontend is the most popular development preferred by businesses. React JS developer assures quality applications with the correct interfaces. This is a wonderful Javascript framework loved by the developers. 
        </p>

      
      </div>
      <div>
        <img
          src={Reactimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>

  <div
    
    name="Tailwind"
    className="h-screen w-full bg-gradient-to-b from-white via-gray-100 to-gray-200" 
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
       <div>
        <img
          src={Tailimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">

        
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        Tailwind
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-lg">
        Rapidly build modern websites without ever leaving your HTML.    
        Tailwind CSS is the only framework that I've seen scale
        on large teams. It’s easy to customize, adapts to any design,
        and the build size is tiny.
        </p>

       
      </div>
     

    
    </motion.div>
 
  </div> 

  <div
    
    name="NextJS"
    className="h-screen w-full bg-gradient-to-b from-gray-200 via-gray-100 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
       NextJS
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-lg">
        Next.js gives you the best developer experience with all the features you need for production: hybrid static & server rendering, TypeScript support, smart bundling, route pre-fetching, and more. No config needed.
        </p>

      
      </div>
      <div>
        <img
          src={Nextimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>

  <div
    
    name="GraphQL"
    className="h-screen w-full bg-gradient-to-b from-white via-gray-100 to-gray-200" 
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
       <div>
        <img
          src={Graphimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">

        
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
        GraphQL
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-lg">
        GraphQL is a query language for APIs and a runtime for fulfilling those queries with your existing data. GraphQL provides a complete and understandable description of the data in your API, gives clients the power to ask for exactly what they need and nothing more, makes it easier to evolve APIs over time, and enables powerful developer tools.
        </p>

       
      </div>
     

    
    </motion.div>
 
  </div> 

  <div
    
    name="Laravel"
    className="h-screen w-full bg-gradient-to-b from-gray-200 via-gray-100 to-white"
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
      <div className="flex flex-col justify-center h-full">
        
        <h2 className="text-4xl sm:text-7xl font-bold text-black">
       Laravel
        </h2> 
        <p className="text-gray-500 py-4 max-w-md text-lg">
        Proving you with the best package of PHP based Laravel CMS development for web apps because we know how to transform your ideas into executable apps with top-notch technology.
        Looking for the Best Laravel Development Company for your dream project? We are right here to deliver you with profoundly secure, flexible, bug free and robust websites.The tech nerds of Systazone Solution are instrumental in offering you the most promising web development services in India.
        </p>

      
      </div>
      <div>
        <img
          src={Laraimg}
          alt="my profile"
          className="rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

    
    </motion.div>
 
  </div>

  <div
    
    name="ASP"
    className="h-screen w-full bg-gradient-to-b from-white via-gray-100 to-white " 
  >
    <motion.div
   
   initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:2}}
    className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
       
       <div>
        <img
          src={Aspimg}
          alt="my profile"
          className=" rounded-2xl mx-auto w-2/3 md:w-full"
        />
      </div>

      <div className="flex flex-col justify-center h-full">

        
        
        <h2 className=" text-4xl sm:text-7xl font-bold text-black">
        ASP
        </h2> 
        <p className="text-gray-500 py-4 max-w-md">
        .NET is a developer platform made up of tools, programming languages, and libraries for building many different types of applications.

        ASP.NET extends the .NET developer platform with tools and libraries specifically for building web apps.
        <li>Fast and scalable</li>
        <p>ASP.NET performs faster than any popular web framework in the independent TechEmpower benchmarks.</p>
        <li>Build secure apps</li>
        <p>ASP.NET supports industry standard authentication protocols. Built-in features help protect your apps against cross-site scripting (XSS) and cross-site request forgery (CSRF).

ASP.NET provides a built-in user database with support for multi-factor authentication and external authentication with Google, Twitter, and more.</p>
        </p>

       
      </div>
     

    
    </motion.div>
 
  </div> 

    </div>
  );
};

export default Skills;