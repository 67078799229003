import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import About from "./components/About";
import Contact from "./components/Contact";
import AllInOne from "./components/exp";

import Home from "./components/Home";
import Navbar from "./components/NavBar";
import Support from "./components/Support";
import Skills from "./components/Skills";
import SocialLinks from "./components/SocialLinks";
import Joinus from "./components/joinus";

import Footer from './components/Footer';
import Terms from "./components/Terms";



function App() {

 
 
  return (
    

    <div>
      
  

<Router>
       <Navbar />    
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/all-in-one" element={<AllInOne />} />
      <Route path="/support" element={<Support />} />
      <Route path="/skills" element={<Skills />} />
      <Route path="/carrier" element={<Joinus />} />
      <Route path="/Term" element={<Terms />} />
       
     
      </Routes>
      
      
      <SocialLinks />
       
     <Footer />
    
    </Router>
   
    
      {/* <NavBar/>
      <Home />
      <About />
      <Support /> 
      <Skills />
      <AllInOne />
      <Contact />  
      <Joinus />
      <SocialLinks />
      <Footer /> */}
    </div>


  );
}
 
export default App;
