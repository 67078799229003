import React from "react";


const Joinus = () => {
  return (
    <div
       name="/carrier"
      className="w-full h-screen bg-gradient-to-b ffrom-white via-gray-200 to-gray-100 mt-20"
    >
      <div className="flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
           Want to Join us ?
          </p>
          <p className="py-6">Submit your CV(Resume) below to join with us</p>
        </div>

        <div className=" flex justify-center items-center">
          <form
            action="https://getform.io/f/4bfd3e5c-91ae-4bc4-ac4f-818bb39c1ed7"
            method="POST"
            className=" flex flex-col w-full md:w-1/2"
          >
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              className="p-2 bg-transparent border-2 rounded-md text-blue-500 focus:outline-none"
            />
            <input
              type="text"
              name="email"
              
              placeholder="Enter your email"
              className="my-4 p-2 bg-transparent border-2 rounded-md text-blue-500 focus:outline-none"
            />
            <textarea
              name="message"
              placeholder="Enter your message"
              rows="2"

              className="p-2 bg-transparent border-2 rounded-md text-blue-500 focus:outline-none"
            ></textarea>
             <input
              type="file"
              name="file"
              rows="5"
              placeholder="select your CV(resume)"
              className="my-4 p-2 bg-transparent border-2 rounded-md text-blue-500 focus:outline-none"
            />

            <button className="text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300">
              Submit 
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Joinus;

